// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const apiUrl = 'https://nookdev.appskeeper.com/v1';
const apiUrl = 'https://api.nook.com.ph/v1';
// const apiUrl = 'https://localhost:7347/v1'

export const environment = {
    production: true,
    apiUrl,
    nookUrl:'https://app.nook.com.ph',
    appNook:"https://app.nook.com.ph",
    tokenKey: `${location.origin}/TOKEN_KEY`,
    tokenRememberKey: `${location.origin}/TOKEN_REMEMBER_KEY`,
    s3: {
      AWS_ACCESS_KEY: 'AKIA6ODTXG53J7AZNCXY',
      AWS_SECRET_KEY: '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',
      REGION: 'ap-northeast-1',
      BUCKET_NAME: 'nook-app'
    },
    tapfiliateID: '31399-79d880',
    tapfiliateSSOSharedKey: 'fc10a0c6a05cf8fcdc5ede7f884735e98567675617e57ce3f24aba4c8cc6390c'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
