import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from "@environment";
import { GlobalService } from "@global";
import { ProfileService } from "@profile";
import { sign } from "jsonwebtoken-esm";
import { ConfirmBoxService } from "src/app/common/confirm-box";
import { IMAGE, MESSAGES } from "src/app/constants";
import { DRAWER_MENUS } from "src/app/constants/drawer.constants";
import { AuthService } from "src/app/modules/auth";
import { PRE_PROCESS_ROUTE } from "src/app/modules/pre-qualification/constants";
import {
  ABOUT_ROUTE,
  ABOUT_STORY_ROUTE,
  ARTICLES_ROUTE,
  BANKS_ROUTE,
  CALCULATORS_ROUTE,
  CONSTANT_ROUTE,
  CONTACT_ROUTE,
  HELP_CENTER_ROUTE,
  LANDING_ROUTE,
  LOGIN_ROUTE,
  MORTGAGE_ROUTE,
  POWER_CALCULATOR_ROUTE,
  PRE_QUALIFICATION_ROUTE,
  PUBLIC_ROUTE,
  REPAYMENT_CALCULATOR_ROUTE,
} from "../../../../constants/route.constants";
import { TapfiliatePayload } from "../header/interfaces/index";

@Component({
  selector: "nook-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  profileData: any;
  isHeaderBanner = false;
  expandable: boolean;
  isToggle: boolean;
  menus = DRAWER_MENUS;
  homeURl = environment.nookUrl;
  private $profileImage: string = null;
  get profileImage() {
    return this.$profileImage || IMAGE.USER_AVTAR_URL;
  }
  get isAuthenticated() {
    return this.profile.isAuthenticated;
  }
  calculatorsUrl: string = CALCULATORS_ROUTE.url;
  mortgageUrl: string = MORTGAGE_ROUTE.url;
  borrowingPowerFragment: string = POWER_CALCULATOR_ROUTE.path;
  monthlyRepaymentFragment: string = REPAYMENT_CALCULATOR_ROUTE.path;
  readonly helpCenterUrl: string = HELP_CENTER_ROUTE.url;
  readonly articlesUrl: string = ARTICLES_ROUTE.url;
  readonly loansUrl: string = PRE_PROCESS_ROUTE.url;
  landingUrl: string = LANDING_ROUTE.url;
  constantRoute = CONSTANT_ROUTE;
  partnerData;
  tapfiliateSSOURL: string;
  tapfiliateTokenPayload: TapfiliatePayload;
  constructor(
    public profile: ProfileService,
    public route: ActivatedRoute,
    private $auth: AuthService,
    public router: Router,
    private confirmBox: ConfirmBoxService,
    private $global: GlobalService,
    public sanitizer: DomSanitizer
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        // console.log(url);
        // equals match url
        // this.isHeaderBanner = [
        //   "",
        //   "/",
        //   ABOUT_ROUTE.url,
        //   MORTGAGE_ROUTE.url,
        //   PRE_QUALIFICATION_ROUTE.url,
        //   ABOUT_STORY_ROUTE.url,
        // ].some((routeUrl) => {
        //   return url === routeUrl;
        // });
        // parent url match
        // this.isHeaderBanner =
        //   this.isHeaderBanner ||
        //   [
        //     BANKS_ROUTE.url,
        //     CALCULATORS_ROUTE.url,
        //     CONTACT_ROUTE.url,
        //     HELP_CENTER_ROUTE.url,
        //     "fbclid",
        //     "utm_source",
        //     "/?",
        //   ].some((routeUrl) => {
        //     return url.indexOf(routeUrl) !== -1;
        //   });
        // setTimeout(() => {
        //   this.checkForPrtnerData()
        // }, 0);
      }
    });
    this.router.events.subscribe(() => {
      this.isToggle = false;
    });
    // route.queryParams.subscribe(({ reset }) => {
    //   if (reset) {
    //     this.$auth.openResetPassword(reset);
    //   }
    // });
    profile.changes.subscribe((data) => {
      this.profileData = data;
      this.$profileImage = (data && data.profilePicUrl) || null;
    });
  }
  onSwitchToTapfiliate() {
    const { _id, email, lastName, firstName, isSSO } = this.profileData;
    const tapfiliateBaseUrl = "https://nook.tapfiliate.com";
    this.tapfiliateTokenPayload = {
      iat: Math.floor(Date.now() / 1000) - 30,
      jti: _id,
      email: email,
      firstname: firstName,
      lastname: lastName,
    };
    const token = sign(
      this.tapfiliateTokenPayload,
      environment.tapfiliateSSOSharedKey,
      { expiresIn: 300 }
    );
    this.tapfiliateSSOURL = `${tapfiliateBaseUrl}/sso/jwt/access/?jwt=${token}`;
    if (!isSSO) {
      this.profile.updateIsSSO(true);
      window.open(this.tapfiliateSSOURL, "_blank");
    } else {
      window.open(`${tapfiliateBaseUrl}/dashboard/`, "_blank");
    }
  }
  checkForPrtnerData() {
    // let partnerEncryptedData = localStorage.getItem('partnerBankData');
    // if (partnerEncryptedData) {
    //   this.partnerData = JSON.parse(atob(partnerEncryptedData));
    // } else {
    this.$global.partnerDataEvent.subscribe((res) => {
      // if (res) {
      this.partnerData = res;
      // }
      // const partnerEncryptedData = localStorage.getItem('partnerBankData');

      // if (res && partnerEncryptedData) {
      //   this.partnerData = JSON.parse(atob(partnerEncryptedData));
      // }
    });
    // }
  }

  ngOnInit(): void {
    // this.$auth.openRegister();
    this.checkForPrtnerData();
  }

  onLoginHandler() {
    // this.$auth.openLogin();
    this.router.navigate([`${PUBLIC_ROUTE.path}/${LOGIN_ROUTE.path}`]);
  }
  onLogoutHandler() {
    this.confirmBox.open(MESSAGES.CONFIRM.LOGOUT).subscribe(async (status) => {
      if (status) {
        await this.profile.logout();
        this.profile.clear();
        this.router.navigate([`${PUBLIC_ROUTE.path}/${LOGIN_ROUTE.path}`]);
      }
    });
  }
  onProfileImageError() {
    if (this.$profileImage) {
      this.$profileImage = null;
    }
  }
  toggle() {
    this.isToggle = !this.isToggle;
  }
}
